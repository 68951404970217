<template>
    <div class="page-contact-success hero-wrapper">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Thank you</h1>

                <p>We'll get in touch with you as soon as possible...</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactSuccess',
    mounted() {
        document.title = 'Request success | ' + this.$store.state.appName
    },
}
</script>