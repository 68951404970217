<template>
    <div class="page-web-service hero-wrapper">
        <div class="content">
            <h1 class="title">Web Services</h1>

            <p><strong>BED</strong> offers a suite of web services that users can
                interact with programmatically. Below, you'll find collections of
                API requests supported by our web services, organized for easy
                access and integration into your projects. Data must be obtained
                using any prgram supporting the HTTP-POST and HTTP-GET methods, e.g.
                <strong><a href="https://curl.se/" target="_blank" rel="noopener noreferrer"
                        style="text-decoration: underline;">CURL</a></strong>.
                Alternatively, click <strong><a
                        :href="`${filePath}web-services/data-management-website.postman_collection.json`"
                        download="data-management-website.postman_collection.json"
                        style="color: #38A935; text-decoration: underline;" target="_blank">
                        Download
                    </a></strong> to get the collections in JSON format and start exploring the
                possibilities with the <strong>BED</strong>'s APIs.
            </p>

            <h2>APIs</h2>
            <p>All APIs can be used with the <code>-o outputfilename.json</code> option to
                store the results in a file.</p>

            <strong>Dataset-related API requests</strong>
            <ul>
                <li @click="toggleDescription('explore')">
                    Filter datasets based on taxonomy strings using a tax keyword</li>
                <div id="explore" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to filter datasets based on taxonomy strings using a tax keyword. By
                        specifying taxonomy strings with the tax keyword, you can narrow down the datasets to those that
                        match your specific criteria, facilitating efficient exploration and analysis. The tax keyword
                        allows you to specify taxonomy strings for different attributes, such as specimen type, specimen
                        sub-type, material type, and material technology.

                    </p>
                    <h6>Parameters</h6>
                    <ul>
                        <li>-H "Content-Type: application/json": This header specifies the content type of the request
                            payload as JSON.</li>
                        <li>-d '{ "tax": "FSA/CR" }': This option sends the taxonomy string with the tax keyword as a
                            JSON object in the request body. Replace "FSA/CR" with your desired taxonomy string using
                            the tax keyword.</li>
                    </ul>
                    <h6>Usage</h6>
                    <p>To filter datasets based on taxonomy strings using the tax keyword, make a POST request to the
                        provided URL with the appropriate headers and payload containing the taxonomy string with the
                        tax keyword.</p>

                    <pre><code>curl -X POST "https://experiments.builtenvdata.eu/api/v1/datasets/explore-results/" -H "Content-Type: application/json" -d "{
                        \"tax\": \"KEYWORD1/KEYWORD2\" }"</code></pre>
                </div>

                <li @click="toggleDescription('filter')">
                    Filter datasets based on metadata</li>
                <div id="filter" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to filter datasets based on metadata. By specifying metadata filters
                        for various attributes, you can narrow down the datasets to those that match your specific
                        criteria, facilitating efficient exploration and analysis. The attributes may include
                        engineering discipline, license type, experiment type, and experiment scale.
                    </p>
                    <h6>Parameters</h6>
                    <ul>
                        <li>-H "Content-Type: application/json": This header specifies the content type of the request
                            payload as JSON.</li>
                        <li>-d '{ "engineeringDiscipline": "Any", "license": "Any", "experimentType": "Any",
                            "experimentScale": "Any" }': This option sends the metadata filters for various
                            attributes as a JSON object in the request body. Replace "Any" with your desired metadata
                            for each attribute. "Any" stands for a default value meaning that all available
                            options will be returned</li>
                    </ul>
                    <h6>Usage</h6>
                    <p>To filter datasets based on metadata, make a POST request to the provided URL with the
                        appropriate headers and payload containing metadata filters for the desired attributes.</p>
                    <pre><code>curl -X POST "https://experiments.builtenvdata.eu/api/v1/datasets/filter-results/" -H "Content-Type: application/json" -d "{
                        \"engineeringDiscipline\": \"Any\",
                        \"license\": \"Any\",
                        \"experimentType\": \"Any\",
                        \"experimentScale\": \"Any\" }"</code></pre>
                </div>

                <li @click="toggleDescription('most-down')">
                    4 most downloaded datasets</li>
                <div id="most-down" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to retrieve information about the four most downloaded datasets from the
                        platform. These datasets are chosen based on their popularity among users, making them
                        particularly relevant for various research and analytical purposes.
                    </p>
                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/most-downloaded-datasets/"</code></pre>
                </div>

                <li @click="toggleDescription('late-down')">
                    4 latest uploaded datasets</li>
                <div id="late-down" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to retrieve information about the four most recently uploaded datasets to
                        the platform. These datasets are selected based on their upload timestamps.
                    </p>
                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/latest-datasets/"</code></pre>
                </div>

                <li @click="toggleDescription('all-data')">
                    All datasets</li>
                <div id="all-data" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to retrieve information about all datasets available on the platform. Each
                        dataset is accompanied by detailed information, including its title, description, metadata,
                        authors, facility, licence, taxonomy, DOI, publications and files associated with the dataset,
                        and download statistics.
                    </p>
                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/all-datasets"</code></pre>
                </div>

                <li @click="toggleDescription('search')">
                    Retrieve datasets based on search query</li>
                <div id="search" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to retrieve datasets based on a search query. By specifying a search query,
                        you can narrow down the datasets to those that match your specific criteria, enabling efficient
                        exploration and analysis. The search query may include text found in
                        experiment title, keywords, description, facility name, Principal Investigator (PI)(s) first and
                        last name.

                    </p>
                    <h6>Parameters</h6>
                    <ul>
                        <li>-H "Content-Type: application/json": This header specifies the content type of the request
                            payload as JSON.</li>
                        <li>-d '{ "query": "query-text" }': This option sends the search query as a JSON object in the
                            request body. Replace "query-text" with your actual search query.</li>
                    </ul>
                    <h6>Usage</h6>
                    <p>To retrieve datasets based on a search query, make a POST request to the provided URL with the
                        appropriate headers and payload. Ensure that you replace <strong>"query-text"</strong> with your
                        desired search query.</p>
                    <pre><code>curl -X POST "https://experiments.builtenvdata.eu/api/v1/datasets/search/" -H "Content-Type: application/json" -d '{ "query": "query-text" }'</code></pre>
                </div>

                <li @click="toggleDescription('data-id')">
                    Retrieve dataset by its ID</li>
                <div id="data-id" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API endpoint enables you to access detailed information about a dataset by providing its
                        unique identifier (ID). Replace this placeholder <strong>`:dataset-id`</strong>, with the actual
                        ID of the dataset you want to retrieve.
                    </p>
                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/datasets/:dataset-id/"</code></pre>
                </div>

                <li @click="toggleDescription('data-filesize')">
                    Filesize in GB</li>
                <div id="data-filesize" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to retrieve the size of a dataset file in gigabytes (GB). Replace this
                        placeholder `<strong>:filename-without-extension</strong>` with the actual filename (without
                        extension) of the dataset file you want to retrieve the size for.
                    </p>
                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/dataset-file/:filename-without-extension"</code></pre>
                </div>

                <li @click="toggleDescription('download-data')">
                    Download dataset zip file</li>
                <div id="download-data" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to download a dataset ZIP file associated with a specific dataset ID. By
                        providing the dataset ID and the filename without extension, you can retrieve the dataset ZIP
                        file.
                    </p>
                    <h6>Parameters</h6>
                    <ul>
                        <li>:dataset-id: Replace this placeholder with the actual ID of the dataset you want to download
                        </li>
                        <li>:filename-without-extension: Replace this placeholder with the actual filename (without
                            extension) of the dataset ZIP file you want to download.</li>
                    </ul>
                    <p>To download the dataset ZIP file, make a GET request to the provided endpoint, replacing
                        :dataset-id with the desired dataset ID and :filename-without-extension with the desired
                        filename.</p>

                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/datasets/:dataset-id/download/:filename-without-extension"</code></pre>
                </div>

                <li @click="toggleDescription('pi')">
                    Principal Investigators (PI) and associated datasets</li>
                <div id="pi" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to retrieve detailed information about a Principal Investigator (PI) and
                        assocaited datasets based
                        on their unique slug identifier.
                    </p>
                    <h6>Parameters</h6>
                    <ul>
                        <li>:pi-slug: Replace this placeholder with the actual slug identifier of the PI you want to
                            retrieve information for. The slug must be replaced by
                            <strong>firstname</strong>-<strong>lastname</strong>, for example: `rui-pinho`
                        </li>
                    </ul>
                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/people/:pi-slug/" </code></pre>
                </div>

                <li @click="toggleDescription('facility')">
                    Facility and associated datasets</li>
                <div id="facility" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to retrieve detailed information about a facility and assocaited datasets
                        based on its unique slug identifier.
                    </p>
                    <h6>Parameters</h6>
                    <ul>
                        <li>:facility-slug: Replace this placeholder with the actual slug identifier of the facility you
                            want to retrieve information for. The slug must be replaced by
                            <strong>facilityname</strong>-lab, for example: `eucentre-lab`
                        </li>
                    </ul>
                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/facility/:facility-slug/"</code></pre>
                </div>

                <li @click="toggleDescription('country')">
                    Country-specific facilities and associated datasets</li>
                <div id="country" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to retrieve information about facilities and associated datasets specific to
                        a particular country. By providing the country name, you can obtain comprehensive details about
                        facilities located in that country and the datasets associated with them, facilitating research
                        planning, collaboration, and exploration of available resources within a specific geographical
                        region.
                    </p>
                    <h6>Parameters</h6>
                    <ul>
                        <li>:country-name: Replace this placeholder with the name of the country for which you want to
                            retrieve information about facilities and associated datasets.
                        </li>
                    </ul>
                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/country/:country-name/"</code></pre>
                </div>

            </ul>

            <p><strong>Taxonomy-related API requests</strong></p>

            <ul>
                <li @click="toggleDescription('mat-tags')">
                    Material types for metadata filters
                </li>
                <div id="mat-tags" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to retrieve a list of material types categorized for metadata filtering.
                        Each material type is associated with metadata filters that help in refining searches and
                        organizing data efficiently.
                    </p>
                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/taxonomy/get-material-types/"</code></pre>
                </div>

                <li @click="toggleDescription('mat-tag')">
                    Material type by its ID
                </li>
                <div id="mat-tag" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API enables you to fetch detailed information about a specific material type based on its
                        unique identifier. Each material type is associated with metadata filters, facilitating
                        efficient data organization and search refinement. To retrieve information about a material type
                        by its ID, simply make a GET request to the provided endpoint, replacing <strong>:id</strong>
                        with the desired material type ID.

                    </p>
                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/taxonomy/get-material-types/:id/"</code></pre>
                </div>

                <li @click="toggleDescription('spec-types')">
                    Specimen types for metadata filters
                </li>
                <div id="spec-types" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API allows you to retrieve a list of specimen types categorized for metadata filtering.
                        Each specimen type is associated with metadata filters that assist in refining searches and
                        organizing data efficiently.
                    </p>
                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/taxonomy/get-specimen-types/"</code></pre>
                </div>

                <li @click="toggleDescription('spec-type')">
                    Specimen type by its ID
                </li>
                <div id="spec-type" class="description mb-2 mt-2" style="display: none">
                    <p>
                        This API enables you to fetch detailed information about a specific specimen type based on its
                        unique identifier. Each specimen type is associated with metadata filters, facilitating
                        efficient data organization and search refinement. To retrieve information about a specimen type
                        by its ID, simply make a GET request to the provided endpoint, replacing <strong>:id</strong>
                        with the desired specimen type ID.
                    </p>
                    <pre><code>curl -X GET "https://experiments.builtenvdata.eu/api/v1/taxonomy/get-specimen-types/:id/"</code></pre>
                </div>
            </ul>

            <strong><i>Dataset DOI-related API requests</i></strong>
            <ul>
                <li>coming soon...</li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    name: "WebServices",
    data() {
        return {
            filePath: `${process.env.BASE_URL}`,
        }
    },
    mounted() {
        document.title = 'Web Services | ' + this.$store.state.appName
    },
    methods: {
        toggleDescription(id) {
            var description = document.getElementById(id)

            if (description.style.display === "none") {
                description.style.display = "block"
            } else {
                description.style.display = "none"
            }
        }
    }
}
</script>


<style scoped>
.content {
    margin: 0 auto;
    font-family: "Montserrat", sans-serif;
    line-height: 1.5rem;
    font-size: 16px;
}

li {
    cursor: pointer;
}

li:hover {
    background-color: #f9f9f9;
}

.description {
    margin-left: 20px;
}
</style>
