<template>
    <div class="page-glossary hero-wrapper">
        <div class="content">
            <h1 class="title">
                Taxonomy
            </h1>

            <p>
                The taxonomy used to classify the types of experiments that are included in the Built Environment Data: Experiments
                service combines a new taxonomy for experimental specimens with the material type and material technology
                attributes from the
                <a href="https://github.com/gem/gem_taxonomy" target="_blank" rel="noopener noreferrer">GEM Building Taxonomy</a>.
            </p>
            <p>
                There are four attributes in the Experimental Test Taxonomy string:
            </p>
            <p>
                Specimen Type / Specimen Sub-type / Material Type / Material Technology.
            </p>
            <p>
                The possible values and associated IDs (used in the taxonomy string) for each of these four attributes are
                described in the following tables. It is noted that this is an evolving taxonomy, and additional attributes
                may need to be added as more experimental test datasets are uploaded.
            </p>

            <table class="table is-half">
                <thead>
                    <tr>
                        <th>Specimen Type</th>
                        <th>ID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>Undefined</td><td>-</td></tr>
                    <tr><td>Structural element</td><td>SE</td></tr>
                    <tr><td>Partial structural assembly</td><td>PSA</td></tr>
                    <tr><td>Full structural assembly</td><td>FSA</td></tr>
                    <tr><td>Non-structural element</td><td>NSE</td></tr>
                    <tr><td>Electrical/mechanical equipment</td><td>EMS</td></tr>
                </tbody>
            </table>

            <table class="table is-half">
                <thead>
                    <tr>
                        <th>Specimen Sub-Type</th>
                        <th>ID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>Undefined</td><td>-</td></tr>
                    <tr><td>Beam</td><td>BEA</td></tr>
                    <tr><td>Column</td><td>COL</td></tr>
                    <tr><td>Slab</td><td>SLA</td></tr>
                    <tr><td>Wall</td><td>WAL</td></tr>
                    <tr><td>Beam-column joint</td><td>BCJ</td></tr>
                    <tr><td>Moment frame</td><td>LFM</td></tr>
                    <tr><td>Infilled frame</td><td>LFINF</td></tr>
                    <tr><td>Braced frame</td><td>LFBR</td></tr>
                    <tr><td>Post and beam</td><td>LPB</td></tr>
                    <tr><td>Dual frame-wall system</td><td>LDUAL</td></tr>
                    <tr><td>Flat slab/plate or waffle slab</td><td>LFLS</td></tr>
                    <tr><td>Infilled flat slab/plate or infilled waffle slab</td><td>LFLSINF</td></tr>
                    <tr><td>Hybrid lateral load-resisting system</td><td>LH</td></tr>
                    <tr><td>Other lateral load-resisting system</td><td>LO</td></tr>
                    <tr><td>Partition wall</td><td>PW</td></tr>
                    <tr><td>False ceiling</td><td>FC</td></tr>
                    <tr><td>Piping</td><td>PIP</td></tr>
                    <tr><td>Air conditioning units</td><td>ACU</td></tr>
                    <tr><td>Computer systems</td><td>CS</td></tr>
                    <tr><td>Medical equipment</td><td>MEQ</td></tr>
                    <tr><td>Transformers</td><td>VTR</td></tr>
                </tbody>
            </table>

            <table class="table is-half">
                <thead>
                    <tr>
                        <th>Material Type</th>
                        <th>ID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>Undefined</td><td>-</td></tr>
                    <tr><td>Concrete, unknown reinforcement</td><td>C99</td></tr>
                    <tr><td>Concrete, unreinforced</td><td>CU</td></tr>
                    <tr><td>Concrete, reinforced</td><td>CR</td></tr>
                    <tr><td>Concrete, composite with steel section</td><td>SRC</td></tr>
                    <tr><td>Steel</td><td>S</td></tr>
                    <tr><td>Metal (except steel)</td><td>ME</td></tr>
                    <tr><td>Masonry, unknown reinforcement</td><td>M99</td></tr>
                    <tr><td>Masonry, unreinforced</td><td>MUR</td></tr>
                    <tr><td>Masonry, confined</td><td>MCF</td></tr>
                    <tr><td>Masonry, reinforced</td><td>MR</td></tr>
                    <tr><td>Earth, unknown reinforcement</td><td>E99</td></tr>
                    <tr><td>Earth, unreinforced</td><td>EU</td></tr>
                    <tr><td>Earth, reinforced</td><td>ER</td></tr>
                    <tr><td>Wood</td><td>W</td></tr>
                    <tr><td>Other material</td><td>MATO</td></tr>
                </tbody>
            </table>

            <table class="table is-half">
                <thead>
                    <tr>
                        <th>Material Technology</th>
                        <th>ID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>Undefined</td><td>-</td></tr>
                    <tr><td>Cast-in-place concrete</td><td>CIP</td></tr>
                    <tr><td>Precast concrete</td><td>PC</td></tr>
                    <tr><td>Cast-in-place prestressed concrete</td><td>CIPPS</td></tr>
                    <tr><td>Precast prestressed concrete</td><td>PCPS</td></tr>
                    <tr><td>Cold-formed steel members</td><td>SL</td></tr>
                    <tr><td>Hot-rolled steel members</td><td>SR</td></tr>
                    <tr><td>Steel, other</td><td>SO</td></tr>
                    <tr><td>Iron</td><td>MEIR</td></tr>
                    <tr><td>Metal, Other</td><td>MEO</td></tr>
                    <tr><td>Adobe blocks</td><td>ADO</td></tr>
                    <tr><td>Stone, unknown technology</td><td>ST99</td></tr>
                    <tr><td>Rubble, field stone, or semi-dressed stone</td><td>STRUB</td></tr>
                    <tr><td>Dressed stone</td><td>STDRE</td></tr>
                    <tr><td>Fired clay unit, any type</td><td>CL99</td></tr>
                    <tr><td>Fired clay solid bricks</td><td>CLBRS</td></tr>
                    <tr><td>Fired clay hollow bricks</td><td>CLBRH</td></tr>
                    <tr><td>Fired clay hollow blocks or tiles</td><td>CLBLH</td></tr>
                    <tr><td>Concrete blocks, any type</td><td>CB99</td></tr>
                    <tr><td>Concrete blocks, solid</td><td>CBS</td></tr>
                    <tr><td>Concrete blocks, hollow</td><td>CBH</td></tr>
                    <tr><td>Masonry unit, other</td><td>MO</td></tr>
                    <tr><td>Rammed earth</td><td>ETR</td></tr>
                    <tr><td>Cob or web construction</td><td>ETC</td></tr>
                    <tr><td>Earth technology, other</td><td>ETO</td></tr>
                    <tr><td>Steel-reinforced</td><td>RS</td></tr>
                    <tr><td>Wood-reinforced</td><td>RW</td></tr>
                    <tr><td>Bamboo-, cane- or rope-reinforced</td><td>RB</td></tr>
                    <tr><td>Fibre reinforcing mesh</td><td>RCM</td></tr>
                    <tr><td>Reinforced concrete bands</td><td>RCB</td></tr>
                    <tr><td>Heavy wood</td><td>WHE</td></tr>
                    <tr><td>Light wood members</td><td>WLI</td></tr>
                    <tr><td>Solid wood</td><td>WS</td></tr>
                    <tr><td>Wattle and daub</td><td>WWD</td></tr>
                    <tr><td>Bamboo</td><td>WBB</td></tr>
                    <tr><td>Wood, other</td><td>WO</td></tr>

                </tbody>
            </table>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "Glossary",
    data() {
        return {

        }
    },
    components: {

    },
    mounted() {
        document.title = 'Taxonomy | ' + this.$store.state.appName
    },
}
</script>

<style scoped>
.content {
    margin: 0 auto;
    font-family: "Montserrat", sans-serif;
    line-height: 1.5rem;
    font-size: 16px;
}
</style>
  