import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { createHead } from '@vueuse/head'

if (process.env.VUE_APP_ENV == "prod") {
    axios.defaults.baseURL = '/'
} else {
    axios.defaults.baseURL = 'http://127.0.0.1:8000'
}

const head = createHead()
const app = createApp(App);

app.use(store).use(head).use(router, axios).mount('#app')
