<template></template>

<script>

export default {
    name: "Admin",
    data() {
        return {

        }
    },
    methods: {
        redirectToAdmin() {
            window.location.href = 'http://localhost:8000/admin/';
        }
    },
    mounted() {
        this.redirectToAdmin();
    },
}
</script>
