<template>
    <div class="column is-4">
        <div class="has-text-centered has-text-weight-medium">{{ title }}</div>

        <div class="dropdown" @click="isOpen = !isOpen" v-bind:class="{ 'is-active': isOpen }" @select="onChange($event)">
            <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span>Any</span>
                    <span class="icon is-small">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                </button>
            </div>
            <div class="dropdown-menu is-fullwidth" id="dropdown-menu" role="menu">
                <div class="dropdown-content is-fullwidth">
                    <a href="#" class="dropdown-item" v-for="dropdownOption in dropdownOptions"
                        v-bind:key="dropdownOption.id" v-bind:value="[dropdownOption.id, dropdownOption.short_name]">
                        {{ dropdownOption.long_name }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropdownOptions',
    props: {
        dropdownOptions: [Object],
        title: "",
    },
    data() {
        return {
            selection: {
                selectionID: '',
                name: '',
            },
            isOpen: false,
        }
    },
    methods: {
        onChange(event) {
            const array = event.target.value.split(",")

            this.selection.selectionID = array[0]
            if (array[1] == null) {
                this.selection.name = 'Any'
            } else {
                this.selection.name = array[1]
            }
            console.log(this.selection)
            this.$emit(
                'selectionChanged', this.selection
            )
        }
    }
}
</script>

<style scoped>
.dropdown {
    width: 100%;
}

.dropdown-trigger {
    width: 100%;
}

button {
    width: 100%;
}

.dropdown-menu {
    width: 100%;
}
</style>