<template>
    <div class="page-person hero-wrapper">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h2 class="is-size-2 has-text-centered">
                    <span>{{ first_name }}</span> <span>{{ person.last_name }}</span>
                </h2>
            </div>

            <div class="column is-12 box" v-if="!isMobile()">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Dataset Title</th>
                            <th>Dataset Facility</th>
                            <th>Year of Experiment</th>
                        </tr>
                    </thead>
                    <GenericProjectItem v-for="project in person.creator_datasets" v-bind:key="project.id"
                        v-bind:project="project" v-bind:page="'PI'" />
                    <tbody>

                    </tbody>
                </table>
            </div>

            <ProjectBox v-else v-for="project in person.creator_datasets" v-bind:key="project.id"
                v-bind:project="project" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import { isMobile, getLocalStorage } from '@/assets/methods.js'
import GenericProjectItem from '@/components/GenericProjectItem'
import ProjectBox from '@/components/ProjectBox'

export default {
    name: 'Person',
    components: {
        GenericProjectItem,
        ProjectBox,
    },
    data() {
        return {
            person: {
                creator_datasets: [],
            },
            first_name: '',
        }
    },
    mounted() {
        this.getPerson()
    },
    methods: {
        isMobile,
        sort(items) {
            items.sort((a, b) => {
                if (a.year_of_experiment < b.year_of_experiment) {
                    return 1;
                }
                if (a.year_of_experiment > b.year_of_experiment) {
                    return -1
                }
                return 0
            })
        },
        async getPerson() {
            this.$store.commit('setIsLoading', true)

            const person_slug = this.$route.params.person_slug

            var datasets = getLocalStorage('datasets')

            if (datasets !== null) {

                this.person.creator_datasets = datasets.filter(dataset => dataset.experiment_pi.find(person => person.slug === person_slug))

                var pi = this.person.creator_datasets[0].experiment_pi.find(experiment_pi => experiment_pi.slug === person_slug)
                
                this.sort(this.person.creator_datasets)
                this.person.first_name = pi.first_name
                this.person.last_name = pi.last_name

            } else {
                await axios
                    .get(`/api/v1/people/${person_slug}`)
                    .then(response => {
                        this.person = response.data
                        this.sort(this.person.creator_datasets)


                    })
                    .catch(error => {
                        console.log(error)

                        toast({
                            message: 'Something went wrong. Please try again',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                    })
            }

            this.first_name = this.person.first_name['0'] + '.'
            document.title = this.first_name + ' ' + this.person.last_name + ' | ' + this.$store.state.appName
            this.$store.commit('setIsLoading', false)
        },
    },
}
</script>