<template>
    <div class="hero-wrapper">
        <div class="page-download">
            <h1 class="title">Download successful!</h1>
        </div>

        <p class="is-size-10 has-text-grey">
            Dataset has been downloaded {{ project.downloads }} times.
        </p>
    </div>
</template>
  
<script>
import axios from 'axios'

export default {
    name: "Download",
    data() {
        return {
            project: Object,
        }
    },
    components: {

    },
    mounted() {
        document.title = 'Download successful | ' + this.$store.state.appName,
            this.getProject()
    },
    methods: {
        async getProject() {
            this.$store.commit('setIsLoading', true)

            const project_id = this.$route.params.project_id

            await axios
                .get(`/api/v1/datasets/${project_id}`)
                .then(response => {
                    this.project = response.data

                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
    },
}
</script>