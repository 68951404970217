<template>
    <div class="page-contact hero-wrapper">
        <section class="hero is-medium is-dark mb-6 flex-start">
            <div class="hero-body has-text-left">
                <p class="title mb-6">
                    Contact Us
                </p>
                <p class="subtitle">
                    Want to get in touch or publish datasets? Here's how you can reach us...
                </p>
                <p>
                    <Icon icon="humbleicons:at-symbol" />
                    info@builtenvdata.eu
                </p>
                <p>
                    <Icon icon="ph:map-pin-line" />
                    Via Ferrata 1, Pavia 27100, Italy
                </p>

                <p class="mt-2">
                    <a :href="`${filePath}BuiltEnvDataUpload-v2.pdf`" target="_blank" rel="noopener noreferrer" style="color: #38A935">
                        Click here</a> for the type of information we ask you to provide us
                </p>

            </div>
        </section>

        <div class="columns is-multiline">
            <div class="column is-12 box">
                <p class="has-text-grey mb-4">* Required fields</p>

                <div class="columns is-multiline">
                    <div class="column is-4">
                        <div class="field">
                            <label>First name*</label>
                            <div class="control has-icons-left">
                                <input type="text" class="input" v-model="first_name">
                                <span class="icon is-small is-left">
                                    <i class="fas fa-user"></i>
                                </span>
                            </div>
                        </div>
                        <div class="field">
                            <label>Last name*</label>
                            <div class="control has-icons-left">
                                <input type="text" class="input" v-model="last_name">
                                <span class="icon is-small is-left">
                                    <i class="fas fa-user"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="column is-4">
                        <div class="field" style="display: none">
                            <label>Phone</label>
                            <div class="control has-icons-left">
                                <input type="text" class="input" v-model="phone">
                                <span class="icon is-small is-left">
                                    <i class="fas fa-phone"></i>
                                </span>
                            </div>
                        </div>
                        <div class="field">
                            <label>Company/University</label>
                            <div class="control has-icons-left">
                                <input type="text" class="input" v-model="company">
                                <span class="icon is-small is-left">
                                    <i class="fas fa-building"></i>
                                </span>
                            </div>
                        </div>
                        <div class="field">
                            <label>Title/Position</label>
                            <div class="control">
                                <input type="text" class="input" v-model="position">
                            </div>
                        </div>
                    </div>

                    <div class="column is-4">
                        <div class="field" style="display: none">
                            <label>How did you hear about us?</label>
                            <div class="control">
                                <input type="text" class="input" v-model="how_hear">
                            </div>
                        </div>
                        <div class="field">
                            <label>E-mail*</label>
                            <div class="control has-icons-left">
                                <input type="email" class="input" v-model="email">
                                <span class="icon is-small is-left">
                                    <i class="fas fa-envelope"></i>
                                </span>
                            </div>
                        </div>
                        <div class="field">
                            <label>Message*</label>
                            <div class="control">
                                <textarea class="input contact-message" v-model="message"></textarea>
                            </div>
                        </div>

                        <div class="column is-3">
                            <button class="button is-dark" @click="submitForm">Submit</button>
                        </div>
                    </div>
                </div>

                <div class="notification is-danger mt-4" v-if="errors.length">
                    <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { Icon } from '@iconify/vue';


export default {
    name: "Contact",
    data() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            company: '',
            position: '',
            how_hear: '',
            message: '',
            errors: [],
            filePath: `${process.env.BASE_URL}`,
        }
    },
    components: {
        Icon,
    },
    mounted() {
        document.title = 'Contact | ' + this.$store.state.appName
    },
    methods: {
        async submitForm() {

            this.errors = []

            if (this.first_name === "") {
                this.errors.push("The first name field is missing!")
            }

            if (this.last_name === "") {
                this.errors.push("The last name field is missing!")
            }

            if (this.email === "") {
                this.errors.push("The email field is missing!")
            }

            if (this.message === "") {
                this.errors.push("The message field is missing!")
            }

            if (!this.errors.length) {
                this.$store.commit('setIsLoading', true)

                const data = {
                    "first_name": this.first_name,
                    "last_name": this.last_name,
                    "email": this.email,
                    // "phone": this.phone,
                    "company": this.company,
                    "position": this.position,
                    // "how_hear": this.how_hear,
                    "message": this.message,
                }

                await axios
                    .post('/api/v1/contact/', data)
                    .then(response => {
                        this.$router.push('/contact/success')
                    })
                    .catch(error => {
                        this.errors.push('Something went wrong. Please try again')

                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            }
        },
    }
}
</script>
