<template>
    <div class="column is-4">
        <div class="field">
            <div class="has-text-centered has-text-weight-medium">{{ title }}</div>
            <div class="select is-fullwidth">
                <select name="option-selection" @change="onChange($event)">
                    <option>Any</option>
                    <option v-for="dropdownOption in dropdownOptions" v-bind:key="dropdownOption.id"
                        v-bind:value="[dropdownOption.id, dropdownOption.short_name]">
                        {{ dropdownOption.long_name }} ({{ dropdownOption.short_name }})
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropdownOptions',
    props: {
        dropdownOptions: [Object],
        title: "",
    },
    data() {
        return {
            selection: {
                selectionID: '',
                name: '',
            }
        }
    },
    methods: {
        onChange(event) {
            const array = event.target.value.split(",")

            this.selection.selectionID = array[0]
            if (array[1] == null) {
                this.selection.name = 'Any'
            } else {
                this.selection.name = array[1]
            }

            this.$emit(
                'selectionChanged', this.selection
            )
        }
    }
}
</script>
