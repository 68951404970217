<template>
  <div class="page-cookies hero-wrapper">
    <div class="content mb-4">
      <h1 class="title">
        Cookie
      </h1>

      <a href="#cookies">1 - Cookies</a><br>
      <!-- <a href="#monitorNavig">2 - Monitoring of navigation data at the site</a><br> -->
      <a href="#personalData">2 - Use of personal data</a><br>
      <!-- <a href="#blockCookies">4 - Blocking cookies</a> -->

      <hr>
      <a name="cookies">
        <h3 class="title">1 - COOKIES</h3>
      </a>
      <strong>What are cookies?</strong>
      <br>
      <p>
        Cookies are information that a server can send to your computer, smartphone or tablet when you visit a website or
        a social network. The same server that transmitted them can then read and record cookies, to obtain information of
        various kinds.
      </p>

      <strong>What type are and what do they need?</strong>
      <br>
      <p>
        Cookies can be:
      </p>
      <ul>
        <li><strong>Technicians:</strong> they are used to make browsing faster and are used, for example, to save the
          contents of an online shopping cart, to memorize the choice of language of a site or to simplify payment
          transactions via home banking.
        </li>
        <li><strong>Profiling:</strong> they are used to monitor users while browsing. They record information about what
          you buy or you may want to buy, analyzing your reading and your preferences. In this way they offer information
          to customize the advertising that is sent to you based on your tastes, choices, preferences, lifestyles. Have
          you ever tried to find information on the destination of your next trip on Google, and then find yourself on
          Facebook promotional offers on that destination? Here, that is a proliferation cookie.
        </li>
        <li><strong>Third-party:</strong> it may happen that in a web page there are cookies from other sites, which are
          contained in other elements (such as the Facebook share button, or a YouTube video embedded in a web page). So
          the cookies you download can be read by other subjects and used to make profiling.
        </li>
      </ul>

      <strong>On this site I use technical and third-party cookies, and no type of profiling cookies.</strong>

      <!-- <hr>
      <a name="monitorNavig">
        <h3 class="title">2 - MONITORING DATA OF NAVIGATION TO THE SITE</h3>
      </a> -->

      <!-- <p>We are interested in offering - day after day - a better service, so this site uses Google Analytics to
        understand how many people surf the site, how they do it and what are the topics they prefer to read.
        Google Analytics is a free service offered by Google: on this website it is set up in such a way as to make your
        <strong>IP address anonymous</strong>. The data collected are stored on Google servers, which may decide to give
        them to third
        parties: know that if this happens, all of this will be anonymous and aggregated. For further information you can
        read directly the <strong><a href="https://marketingplatform.google.com/about/" target="_blank" rel="noopener noreferrer">privacy policy and the use of
            Google Analytics data</a></strong>.
        If you do not want to appear among visitors to this site you can make yourself invisible by downloading an add-on
        for <strong><a href="https://tools.google.com/dlpage/gaoptout?hl=it" target="_blank" rel="noopener noreferrer">Google Analytics</a></strong>.
      </p> -->

      <hr>
      <a name="personalData">
        <h3 class="title">2 - USE OF PERSONAL DATA</h3>
      </a>

      <p>
        This site has a Contact form. If you write us, we use your data (email address) to reply to you. These data remain
        saved in our mail management software and on our website server. We are the owners of this data, which we do not
        transfer or sell to anyone.
      </p>

      <!-- <hr>
      <a name="blockCookies">
        <h3 class="title">4 - BLOCKING COOKIES</h3>
      </a>

      <p>
        If you do not want your data to be recorded, you can choose to block cookies from your browser. Below you will
        find how to do:
      </p>

      <ul>
        <li><a href="https://support.google.com/accounts/answer/61416?hl=it" target="_blank" rel="noopener noreferrer">Chrome</a></li>
        <li><a href="https://support.apple.com/it-it/HT201265" target="_blank" rel="noopener noreferrer">Safari</a></li>
        <li><a href="https://support.mozilla.org/it/kb/Bloccare%20i%20cookie" target="_blank" rel="noopener noreferrer">Firefox</a></li>
        <li><a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=Select%20Settings%20%3E%20Privacy%2C%20search%2C,and%20then%20select%20Clear%20now." target="_blank" rel="noopener noreferrer">Microsoft Edge</a></li>
      </ul>
      <br> -->
      
      If something about the use of your data is not clear to you, you can 
      <router-link to="/publish">
        write to us.
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  name: "Cookies",
  data() {
    return {

    }
  },
  components: {

  },
  mounted() {
    document.title = 'Cookies | ' + this.$store.state.appName
  },
}
</script>

<style scoped>
.content {
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5rem;
  font-size: 16px;
}
</style>
