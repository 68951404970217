import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoading: false,
    appName: "Built Environment Data: Experiments",
    url: "https://experiments.builtenvdata.eu",
  },
  getters: {
  },
  mutations: {
    setIsLoading(state, status) {
        state.isLoading = status
    },
  },
  actions: {
  },
  modules: {
  }
})
