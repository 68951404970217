function isMobile() {
    if (screen.width <= 760) {
        return true
    } else {
        return false
    }
};

function setLocalStorage(key, value, ttl=3.6e+6) {
    const now = new Date()

    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }

    localStorage.setItem(key, JSON.stringify(item))
};

function getLocalStorage(key) {
    const itemStr = localStorage.getItem(key)
    
    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

function addAttributesToLinks(htmlContent) {
    // Create a temporary DOM element to parse the HTML content
    const tempEl = document.createElement('div')
    tempEl.innerHTML = htmlContent

    // Loop through all 'a' elements and add the attributes
    const links = tempEl.querySelectorAll('a')
    links.forEach(link => {
        const href = link.getAttribute('href');

        if (href && !href.startsWith('http') && !href.startsWith('https')) {
            return; // Skip local URLs
        }

        link.setAttribute('target', '_blank')
        link.setAttribute('rel', 'noopener noreferrer')
    
    })
    
    return tempEl.innerHTML
}

function sort(items) {
    items.sort((a, b) => {
        if (a.year_of_experiment < b.year_of_experiment) {
            return 1;
        }
        if (a.year_of_experiment > b.year_of_experiment) {
            return -1
        }
        return 0
    })
}

function removeExtraSlashes(string) {
    return string.replace(/\/+/g, '/');
}

export { isMobile, setLocalStorage, getLocalStorage, addAttributesToLinks, sort, removeExtraSlashes }
