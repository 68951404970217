<template>
    <tr>
        <td><router-link :to="project.get_absolute_url">{{ project.experiment_title }}</router-link></td>
        <td>
            <p v-for="experiment_pi in project.experiment_pi" v-bind:key="experiment_pi.id"
                v-bind:experiment_pi="experiment_pi">

                <span class="has-text-link" @click="goTo(experiment_pi.get_absolute_url)" style="cursor: pointer">
                    {{ experiment_pi.first_name['0'] }}. {{ experiment_pi.last_name }}
                </span>
                
                <router-view :key='$route.fullPath' />

            </p>
        </td>
        <td>{{ project.keywords }}</td>
        <td>{{ project.custom_date_added }}</td>
    </tr>
</template>

<script>
export default {
    name: "ProjectItem",
    props: {
        project: Object
    },
    methods: {
        goTo(param) {
            console.log(param)
            this.$router.push({ path: param })
            .then(() => { this.$router.go() })
        }
    }
}
</script>