<template>
    <div class="home hero-wrapper">
        <section class="hero is-medium is-dark mb-6 flex-start" style="display: none">
            <!-- <link rel="preload" placeholder="hero" fetchpriority="high" as="image" href="../assets/images/hero2.webp" type="image/webp"> -->

            <!-- <div class="hero-body has-text-left" style="cursor: pointer;" onclick="location.href='/world-map'"> -->
            <div class="hero-body has-text-left">
                <h1 class="title mb-6">
                    Built Environment Data: Experiments
                </h1>
                <h2 class="subtitle">
                    Explore experimental datasets from the engineering community with ease
                </h2>

                <router-link to="/explore" class="button is-light mt-4 mr-4"
                    onclick="event.stopPropagation(); window.event.cancelBubble = true">Explore Datasets</router-link>
                <router-link to="/publish" class="button is-light mt-4"
                    onclick="event.stopPropagation(); window.event.cancelBubble = true">Publish Datasets</router-link>

            </div>
        </section>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h2 class="is-size-2 has-text-centered">Most Downloaded Datasets</h2>
            </div>

            <ProjectBox v-for="project in mostDownloadedDatasets" v-bind:key="project.id" v-bind:project="project" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { register } from 'swiper/element/bundle';

register();

import { setLocalStorage, getLocalStorage } from '@/assets/methods.js'
import ProjectBox from '@/components/ProjectBox'
import { useHead } from '@vueuse/head';

export default {
    name: 'Home',
    data() {
        return {
            // Most-downloaded (even though it says latest)
            mostDownloadedDatasets: []
        }
    },
    setup() {
        useHead({
            title: "Built Environment Data: Experiments",
            meta: [
                { name: 'description', content: "Explore and access built environment test data. This data includes experimental test results for building and infrastructure performance assessment in Europe. Validate and calibrate numerical models for better vulnerability assessment." },
                { property: 'og:description', content: "Explore and access built environment test data. This data includes experimental test results for building and infrastructure performance assessment in Europe. Validate and calibrate numerical models for better vulnerability assessment." },
                { property: 'og:title', content: "Built Environment Data: Experiments" },
                { property: 'og:type', content: "website" },
            ]
        })

        const spaceBetween = 10;
        const onProgress = (e) => {
            const [swiper, progress] = e.detail;
        };

        return {
            spaceBetween,
            onProgress,
        };
    },
    components: {
        ProjectBox,
    },
    mounted() {
        document.title = this.$store.state.appName

        this.getmostDownloadedDatasets()
    },
    methods: {
        async getmostDownloadedDatasets() {
            this.$store.commit('setIsLoading', true)

            var storage = getLocalStorage('mostDownloadedDatasets')

            if (storage !== null) {
                this.mostDownloadedDatasets = storage

            } else {
                await axios
                    .get('api/v1/most-downloaded-datasets/')
                    .then(response => {
                        this.mostDownloadedDatasets = response.data
                        // local storage with an expiry of 1 hr
                        setLocalStorage('mostDownloadedDatasets', this.mostDownloadedDatasets)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            this.$store.commit('setIsLoading', false)

        }
    },
}
</script>

<style>
@import '../assets/styles/base.scss';
@import '../assets/styles/style.css';

.hero {
    background-image: url('../assets/images/hero2.webp');
    background-size: 100%;
}

/* .hero.is-medium .hero-body {
  padding-top: 2rem;
  padding-bottom: 14rem;
} */
</style>
