<template>
    <div class="page-search hero-wrapper">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Search</h1>

                <h2 class="is-size-5 has-text-grey">Search term "{{ query }}"</h2>
            </div>

            <div class="column is-12 box" v-if="!isMobile">
                <table class="table is-fullwidth" v-if="projectLength">
                    <thead>
                        <tr>
                            <th>Dataset Title</th>
                            <th>Dataset PI(s)</th>
                            <th>Dataset Facility</th>
                            <th>Year of Experiment</th>
                        </tr>
                    </thead>
                    <GenericProjectItem 
                        v-for="project in projects" 
                        v-bind:key="project.id" 
                        v-bind:project="project"
                        v-bind:page="'home'" />
                    <tbody>

                    </tbody>
                </table>

                <p v-else>No datasets were found...</p>
            </div>

            <ProjectBox v-else v-for="project in projects" v-bind:key="project.id" v-bind:project="project" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import { isMobile } from '@/assets/methods.js'
import GenericProjectItem from '@/components/GenericProjectItem'
import ProjectBox from '@/components/ProjectBox'

export default {
    name: "Search",
    components: {
        GenericProjectItem,
        ProjectBox,
    },
    data() {
        return {
            projects: [],
            query: ''
        }
    },
    mounted() {
        document.title = 'Search | ' + this.$store.state.appName

        let uri = window.location.search.substring(1)

        let params = new URLSearchParams(uri)

        if (params.get('query')) {
            this.query = params.get('query')

            this.performSearch()
        }
    },
    methods: {
        async performSearch() {
            this.$store.commit('setIsLoading', true)

            await axios
                .post('/api/v1/datasets/search/', { 'query': this.query })
                .then(response => {
                    this.projects = response.data
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    },
    computed: {
        projectLength() {
            return this.projects.length
        },
        isMobile,
    },
}
</script>
