<template>
    <div class="page-country hero-wrapper">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h2 class="is-size-2 has-text-centered">{{ this.country_name }}</h2>

                <p class="primary-text has-text-weight-medium mb-4">Facility Filter</p>

                <div class="columns is-multiiline is-left">
                    <MetadataDropdownFilter v-bind:dropdownOptions="facilityOptions" defaultChoice="Any"
                        v-on:filterSelection="facilityValue = $event" v-on:change="filterProjects();" />
                </div>
                <hr>
            </div>
        </div>

        <div class="columns is-multiline">
            <div class="column is-2">
                <div class="columns is-multiiline">
                    <div class="column is-one-quarter">
                        <p>Show</p>
                    </div>
                    <div class="column is-half">
                        <div class="field">
                            <div class="select is-fullwidth">
                                <select name="option-selection" @change="changeNumberOfDatasetsPerPage($event)">
                                    <option v-for="dropdownOption in pageSizeList">
                                        {{ dropdownOption }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-12 box" v-if="!isMobile()">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Dataset Title</th>
                            <th>Dataset PI(s)</th>
                            <th>Dataset Facility</th>
                            <th>Year of Experiment</th>
                        </tr>
                    </thead>
                    <GenericProjectItem v-for="project in visibleProjects" v-bind:currentPage="currentPage"
                        v-bind:key="project.id" v-bind:project="project" v-bind:page="'home'" />
                    <tbody>

                    </tbody>
                </table>
            </div>

            <ProjectBox v-else v-for="project in visibleProjects" v-bind:currentPage="currentPage" v-bind:key="project.id"
                v-bind:project="project" />

            <Pagination v-bind:projects="filteredProjects" v-on:page:update="updatePage" v-bind:currentPage="currentPage"
                v-bind:pageSize="pageSize" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import { isMobile, getLocalStorage, setLocalStorage } from '@/assets/methods.js'
import GenericProjectItem from '@/components/GenericProjectItem'
import ProjectBox from '@/components/ProjectBox'
import MetadataDropdownFilter from '@/components/MetadataDropdownFilter'
import Pagination from '@/components/Pagination'

export default {
    name: 'Country',
    components: {
        GenericProjectItem,
        ProjectBox,
        MetadataDropdownFilter,
        Pagination,
    },
    data() {
        return {
            projects: [],
            facilities: [],
            facilityOptions: [],
            country_name: "",
            facilityValue: 'Any',
            currentPage: 0,
            pageSize: 10,
            filteredProjects: [],
            visibleProjects: [],
            pageSizeList: [10, 20, 50, 100],
        }
    },
    mounted() {
        this.getCountryLabs()
    },
    methods: {
        isMobile,
        changeNumberOfDatasetsPerPage(event) {
            this.pageSize = parseInt(event.target.value)

            this.updatePage(0)
        },
        updatePage(pageNumber) {
            this.currentPage = pageNumber;
            this.updateVisibleProjects();
        },
        updateVisibleProjects() {
            this.visibleProjects = this.filteredProjects.slice(
                this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize
            );

            // go back a page
            if (this.visibleProjects.length == 0 && this.currentPage > 0) {
                this.updatePage(this.currentPage - 1);
            }
        },
        async getCountryLabs() {
            this.$store.commit('setIsLoading', true)

            this.country_name = this.$route.params.country_slug.toUpperCase()

            document.title = this.country_name + " | " + this.$store.state.appName

            var facilities = getLocalStorage(this.country_name + '-facilities')

            if (facilities !== null) {
                this.facilities = facilities

            } else {

                await axios
                    .get(`/api/v1/country/${this.country_name}`)
                    .then(response => {
                        this.facilities = response.data
                        
                        setLocalStorage(this.country_name + '-facilities', this.facilities)
                    })
                    .catch(error => { 
                        console.log(error)

                        toast({
                            message: 'Something went wrong. Please try again',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })
                    })
            }
            let facilityOptions = []
            let projects = []

            this.facilities.forEach(function (facility) {
                facilityOptions.push(facility.name)
                projects.push(...facility.facility_datasets)
            })

            this.facilityOptions = facilityOptions.sort()
            this.filteredProjects = projects
            this.projects = projects

            this.updateVisibleProjects()
            this.filterProjects()

            this.$store.commit('setIsLoading', false)

        },
        filterProjects() {

            this.filteredProjects = this.projects.filter(
                project => (
                    project.experiment_facility.some(
                        facility => facility.name === this.facilityValue || this.facilityValue === "Any"
                    )
                )
            )

            this.updatePage(0)

        }
    }
}
</script>