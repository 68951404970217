<template>
  <div class="page-privacy-terms hero-wrapper">
    <div class="content">
      <h2 class="title">
        Privacy Policy
        <br><span style="font-size: 80%;">Last Updated On 07-Mar-2023</span>
        <br><span style="font-size: 80%;">Effective Date 07-Mar-2023</span>
      </h2>

      <p class="is-size-10">This Privacy Policy describes the policies of Built Environment Data: Experiments, Via Ferrata 3,
        Pavia
        27100, Italy, email: info@betestdata.eu, phone: +390382985711 on the collection, use and disclosure of your
        information that we collect when you use our website 
        (<a href="https://experiments.builtenvdata.eu/">https://experiments.builtenvdata.eu/</a>). (the “Service”). By accessing or using the
        Service, you are consenting to the collection, use and disclosure of your information in accordance with this
        Privacy Policy. If you do not consent to the same, please do not access or use the Service.</p>

      <p class="is-size-10">
        We may modify this Privacy Policy at any time without
        any prior notice to you and will post the revised
        Privacy Policy on the Service. The revised Policy will
        be effective 180 days from when the
        revised Policy is posted in the Service and your
        continued access or use of the Service after such time
        will constitute your acceptance of the revised Privacy
        Policy. We therefore recommend that you periodically
        review this page.
      </p>

      <ul>
        <li style="font-weight: bold; text-decoration: underline;">
          HOW WE USE YOUR INFORMATION:
        </li>
        <br>

        We will use the information that we collect about you for the following purposes:

        <ul>
          <li>Site protection</li>
        </ul>

        If we want to use your information for any other purpose, we will ask you for consent and will use your
        information only on receiving your consent and then, only for the purpose(s) for which grant consent unless we are
        required to do otherwise by law.

        <br>
        <br>
        <li style="font-weight: bold; text-decoration: underline;">
          HOW WE SHARE YOUR INFORMATION:
        </li>
        <br>

        We will not transfer your personal information to any third party without seeking your consent, except in limited
        circumstances as described below:

        <ul>
          <li>Analytics</li>
        </ul>

        We require such third party’s to use the personal information we transfer to them only for the purpose for which
        it was transferred and not to retain it for longer than is required for fulfilling the said purpose.

        We may also disclose your personal information for the following: (1) to comply with applicable law, regulation,
        court order or other legal process; (2) to enforce your agreements with us, including this Privacy Policy; or (3)
        to respond to claims that your use of the Service violates any third-party rights. If the Service or our company
        is merged or acquired with another company, your information will be one of the assets that is transferred to the
        new owner.

        <br>
        <br>
        <li style="font-weight: bold; text-decoration: underline;">
          YOUR RIGHTS:
        </li>
        <br>

        Depending on the law that applies, you may have a right to access and rectify or erase your personal data or
        receive a copy of your personal data, restrict or object to the active processing of your data, ask us to share
        (port) your personal information to another entity, withdraw any consent you provided to us to process your data,
        a right to lodge a complaint with a statutory authority and such other rights as may be relevant under applicable
        laws. To exercise these rights, you can write to us at info@betestdata.eu. We will respond to your request in
        accordance with applicable law.

        Do note that if you do not allow us to collect or process the required personal information or withdraw the
        consent to process the same for the required purposes, you may not be able to access or use the services for which
        your information was sought.

        <br>
        <br>
        <li style="font-weight: bold; text-decoration: underline;">
          COOKIES ETC.
        </li>
        <br>

        To learn more about how we use these and your choices in relation to these tracking technologies, please refer to
        our.
        <router-link to="/cookies">
          Cookie Policy.
        </router-link>

        <br>
        <br>
        <li style="font-weight: bold; text-decoration: underline;">
          SECURITY:
        </li>
        <br>
        The security of your information is important to us and we will use reasonable security measures to prevent the
        loss, misuse or unauthorized alteration of your information under our control. However, given the inherent risks,
        we cannot guarantee absolute security and consequently, we cannot ensure or warrant the security of any
        information you transmit to us and you do so at your own risk.

        <br>
        <br>
        <li style="font-weight: bold; text-decoration: underline;">
          THIRD PARTY LINKS & USE OF YOUR INFORMATION:
        </li>
        <br>

        Our Service may contain links to other websites that are not operated by us. This Privacy Policy does not address
        the privacy policy and other practices of any third parties, including any third party operating any website or
        service that may be accessible via a link on the Service. We strongly advise you to review the privacy policy of
        every site you visit. We have no control over and assume no responsibility for the content, privacy policies or
        practices of any third party sites or services.

        <br>
        <br>
        <li style="font-weight: bold; text-decoration: underline;">
          GRIEVANCE / DATA PROTECTION OFFICER:
        </li>
        <br>

        If you have any queries or concerns about the processing of your information that is available with us, you may
        email our Grievance Officer at Built Environment Data, DICAr, Via Ferrata 3, Pavia, Italy, email:
        info@betestdata.eu. We will address your concerns in accordance with applicable law.

      </ul>

    </div>


  </div>
</template>

<script>
export default {
  name: "Privacy",
  data() {
    return {

    }
  },
  mounted() {
    document.title = 'Privacy & Terms | ' + this.$store.state.appName
  },
}
</script>

<style scoped>
.content {
    margin: 0 auto;
    font-family: "Montserrat", sans-serif;
    line-height: 1.5rem;
}
</style>