<template>
    <div v-if="totalPages() > 0" class="pagination-wrapper">
        <span v-if="showPreviousLink()" class="pagination-btn" v-on:click="updatePage(currentPage - 1)"> Previous </span>
        <span>Page {{ currentPage + 1 }} of {{ totalPages() }}</span>
        <span v-if="showNextLink()" class="pagination-btn" v-on:click="updatePage(currentPage + 1)"> Next </span>
    </div>
</template>

<script>
export default {
    name: "PaginationItem",
    props: ['projects', 'currentPage', 'pageSize'],
    methods: {
        updatePage(pageNumber) {
            this.$emit('page:update', pageNumber)
        },
        totalPages() {
            return Math.ceil(this.projects.length / this.pageSize)
        },
        showPreviousLink() {
            return this.currentPage == 0 ? false : true
        },
        showNextLink() {
            return this.currentPage == (this.totalPages() - 1) ? false : true
        }
    },
}
</script>

<style scoped>
.pagination-btn {
    cursor: pointer;
    color: blue;
}

.pagination-btn:hover {
    background-color: rgba(187, 186, 186, 0.65);
}

.pagination-btn:active {
    background-color: rgba(145, 134, 134, 0.65);
}
</style>