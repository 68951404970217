<template>
    <div class="column is-4">
        <div class="field">
            <div class="has-text-centered has-text-weight-medium">{{ title }}</div>
            <div class="select is-fullwidth">
                <select name="option-selection" @change="onChange($event)">
                    <option>{{ defaultChoice }}</option>
                    <option v-for="dropdownOption in dropdownOptions">
                        {{ dropdownOption }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropdownOptions',
    props: {
        dropdownOptions: Array,
        title: String,
        defaultChoice: {
            type: String,
            default: "Any"
        }
    },
    data() {
        return {
            selection: '',
        }
    },
    methods: {
        onChange(event) {
            const array = event.target.value.split(",")

            this.selection = array[0]
            
            if (array[0] == null) {
                this.selection = 'Any'
            } else {
                this.selection = array[0]
            }

            this.$emit(
                'filterSelection', this.selection,
                'defaultChoice', this.defaultChoice
            )
        }
    }
}
</script>
