<template>
    <div class="page-not-found hero-wrapper">
        <h1>Page Not Found</h1>

        <p>
            oh, the humanity, the hateful 404.
        </p>
    </div>
</template>

<script>

export default {
    name: "PageNotFound",
    data() {
        return {

        }
    },
    components: {

    },
    mounted() {
        document.title = 'Page Not Found | ' + this.$store.state.appName
    },
}
</script>

<style scoped>
h1 {
    font-size: 42px;
    font-weight: bold;
}

p {
    font-size: 30px;
}
</style>